import React, { useEffect, useState } from 'react';
import { Button, Icon, keyframes  } from '@chakra-ui/react';
import { ChevronUpIcon } from '@chakra-ui/icons'

const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const BackToTopButton = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      {showButton && (
        <Button
            onClick={scrollToTop}
            position="fixed"
            bottom="20px"
            right="20px"
            colorScheme="teal"
            borderRadius="full"
            boxShadow="md"
            animation={`${showButton ? fadeIn : fadeOut} 0.5s, ${bounce} 2s infinite`}
            opacity={showButton ? 1 : 0}
            pointerEvents={showButton ? 'auto' : 'none'}
        >
          <Icon as={ChevronUpIcon} w={6} h={6} />
        </Button>
      )}
    </>
  );
};

export default BackToTopButton;
